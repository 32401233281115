import React from 'react';
import Layout from "../components/layout";
import Helmet from "../components/helmet";
import Image from "../components/image";
import GoogleMap from '../page_content/googlemap.mdx';

let config = {
  pageTitle: 'Kontakt'
}


export default ({ children, ...props }) => {
    return (
      <Layout>
        <Helmet title={config.pageTitle}/>


        <div className="columns">
          <div className="column is-half has-text-centered">
            
            <Image filename="passfoto1.jpg" className="has-shadow-default" alt="Wolfgang Engelhardt" imageStyle={{objectFit: "cover"}} style={{width: "140px", height: "140px", borderRadius: "50%", margin: "2rem auto 0 auto"}}/>

            <h2>Wolfgang Engelhardt</h2>

            <p>
              <span role="img" aria-label="phone icon">📞</span> <a href="tel:+436644533121">+43 664 4533121</a><br/>
              <span role="img" aria-label="letter icon">✉️</span> <a href="mailto:wolf.engelhardt@gmail.com">wolf.engelhardt@gmail.com</a>
            </p>

            <p>
              Im Dörfl 3<br/>
              3100 St. Pölten-Unterwagram<br/>
              Öster­re­ich<br/>
            </p>

          </div>
          <div className="column is-half">
            <GoogleMap/>
          </div>
        </div>


        {children}
      </Layout>
    )
}
