import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <iframe width="600" height="450" frameBorder="0" style={{
      "border": "0",
      "backgroundColor": "#efefef",
      "width": "100%"
    }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ12zHv0uHbUcRGETZ2ZZqY_I&key=AIzaSyD9XWCrXFaXNW3BTFy6RExA8S995CeM_Rc" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      